export const environment = {
  production: false,
  networkIds: {
    1: 'https://goerli.etherscan.io/tx/', //ethereum(1)
    2: 'https://goerli.arbiscan.io/tx/', //arbitrum(2)
    3: '' //@TODO add fabric tx receipt link for dev
  },
  auth0: {
    domain: 'https://validation.login.veratrak.com',
    audience: 'validation-veratrak.eu.auth0.com',
    clientId: 'iT1Yjs4VQvZrN9dXsLHzbAs4ytbT7kD5'
  },
  vapid: 'BFPVeUV_hMp-Pe4fv7IUfpQSoQrdMy2zaSjzQRsUEPezTusdsldFSnBJrnGR4-61b8rV2Y9d5bioTMw1rc6gshY',
  apiHost: 'https://api.qa.hub.veratrak.com',
  socketHost: 'wss://socket.qa.hub.veratrak.com',
  jsonCrackHost: 'https://jsoncrack.qa.hub.veratrak.com',
  jsoncrack: 'https://jsoncrack.qa.hub.veratrak.com/widget',
  gtm: 'GTM-T7B4X69'
};
